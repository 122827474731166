<template>
    <div>
        <div v-if="RatingDetailItem">
            <div class="d-flex align-items-center w-100 mb-2">
                <div class="mr-4">
                    <el-tooltip v-if="RatingDetailItem.username !== null"
                                class="item"
                                effect="light"
                                placement="top"
                                :content="`${
                                listStaff.find((x)=>
                                    x.email === RatingDetailItem.staffCare) !==
                                    undefined
                                    ? listStaff.find((x) => x.email === RatingDetailItem.staffCare)
                                    .fullName
                                    : ''
                                    }`"
                                    >
                        <v-avatar size="4rem">
                            <v-img v-if="RatingDetailItem.staffCare !== null"
                                    id="app-avatar"
                                    :src="`/img/avatars/${RatingDetailItem.staffCare}.jpg`"
                                    onerror="this.src='/img/avatar5.png'" />
                            <v-img v-else
                                    id="app-avatar"
                                    :src="`/img/amy4.jpg`"
                                    onerror="this.src='/img/avatar5.png'" />
                        </v-avatar>
                    </el-tooltip>
                </div>
                <div class="text-nowrap mr-4">
                    <ColumnUser :element="RatingDetailItem.columnUser" />
                </div>
                <div class="mr-5">
                    <ColumnBalance :userId="RatingDetailItem.userId" />
                </div>
                <div>
                    <div class="d-flex flex-column">
                        <div title="Billing Code"
                                style="color: #9e9e9e; font-size: 0.9rem">
                            {{ RatingDetailItem.billingCode }}
                        </div>
                        <div class="d-flex flex-row align-items-center mt-2">
                            <div class="d-flex align-items-center pl-3 pr-3"
                                    style="font-family: Montserrat; font-size: 0.7rem; background-color: #64b5f6; color: white; border-radius: 0.2rem; padding: 0.05rem 0;">
                                {{ RatingDetailItem.machineId }}
                            </div>
                            <div class="ml-2"
                                    style="font-size: 0.8rem; color: #424242; font-weight: 500;">
                                {{ RatingDetailItem.machineName }}
                            </div>
                        </div>
                        <div class="d-flex flex-row align-items-center font-size-8 mt-2">
                            <div>
                                <img style="width: 2rem"
                                        :src="`/serviceType/${RatingDetailItem.serviceType}.svg`" />
                            </div>
                            <div class="ml-2" style="color: #42a5f5">
                                {{ RatingDetailItem.packageName }}
                            </div>
                            <div class="ml-2" style="color: #9e9e9e">
                                ( {{ RatingDetailItem.packageDescription }} )
                            </div>
                        </div>
                    </div>
                </div>
                <div class="flex-fill text-right"
                     v-if="RatingDetailItem.status === 4">
                    <el-button class="bg-gradient-success" round
                               @click="closeThread()">
                        <span class="text-white"><i class="el-icon-circle-check"></i> Close thread</span>
                    </el-button>
                </div>
            </div>
            <div v-if="RatingDetailItem.note !== null">
                <h5><strong>Note content</strong></h5>
                <div class="text=muted">
                    {{RatingDetailItem.note}}
                </div>
            </div>
            <div class="mt-3 position-relative">
                <el-button type="primary" round size="mini"
                           class="position-absolute" v-if="tabCurrent === 'response'"
                           style="right: 0.5rem; top: 0.4rem; z-index: 99;"
                           @click="getData">
                    <span><i class="el-icon-refresh"></i> Refresh</span>
                </el-button>
                <div class="position-absolute" v-else
                    style="right: 0.5rem; top: 0.4rem; z-index: 99;">
                    <el-button type="info" round size="mini"
                           @click="markRead">
                    <span><i class="el-icon-check"></i> Mark read</span>
                    </el-button>
                    <el-button type="primary" round size="mini"
                               @click="getInternalResponseData">
                        <span><i class="el-icon-refresh"></i> Refresh</span>
                    </el-button>
                </div>
                <el-tabs type="border-card" v-model="tabCurrent">
                    <el-tab-pane label="Internal conversation" name="internal">
                        <span slot="label">
                            <i class="el-icon-chat-line-round"></i> Internal conversation 
                            <span class="badge badge-danger ml-1 mr-2" v-if="newInternalResponseCount > 0">
                                {{newInternalResponseCount}}
                            </span>
                        </span>
                        <div class="mb-2 d-flex" v-loading="loadingSubmit">
                            <div class="image mr-2">
                                <img :src="`/img/avatars/${userInfo.userEmail}.jpg`"
                                     onerror="this.src='/img/avatar5.png'"
                                     style="width: 35px;"
                                     class="img-circle" />
                            </div>
                            <div class="mr-2 flex-fill">
                                <el-input type="textarea" placeholder="Enter comment content..."
                                          v-model="responseInternalModel.msgContent" />
                                <div class="mt-1 w-100">
                                    <el-select v-model="responseInternalModel.mentionedList" filterable multiple placeholder="mention"
                                               class=" w-100" size="small">
                                        <el-option v-for="item in listStaff.filter(x => x.status === 1)"
                                                   :key="item.email"
                                                   :label="item.fullName"
                                                   :value="item.email">
                                        </el-option>
                                    </el-select>
                                </div>
                                <div class="mt-2">
                                    <el-switch v-model="responseInternalModel.notifyOnTelegram"/>
                                    <span class="ml-2">Notify on Telegram support group</span>
                                </div>
                            </div>
                            <div>
                                <el-button type="primary" round size="small"
                                           :disabled="!responseInternalModel.msgContent"
                                           @click="submitInternalResponse">
                                    <span><i class="far fa-paper-plane"></i> Send</span>
                                </el-button>
                            </div>
                        </div>
                        <hr/>
                        <div class="mt-3">
                            <div class="timeline mb-1"
                                 v-if="internalResponseData.listData.length > 0"
                                 v-loading="loadingData"
                                 style="margin-left: -1rem; margin-right: -1.5rem;">
                                <!-- timeline item -->
                                <div class="d-flex" :key="commentItem.id"
                                     v-for="commentItem in internalResponseData.listData">
                                    <div class="image ml-3">
                                        <img :src="`/img/avatars/${commentItem.CreatedBy}.jpg`"
                                             onerror="this.src='/img/avatar5.png'"
                                             style="width: 35px;"
                                             class="img-circle elevation-2" />
                                    </div>
                                    <div class="timeline-item ml-2 w-100"
                                         v-bind:class="{ 'customer-conv' : commentItem.MsgDirection === 2 }">
                                        <span class="time">
                                            <i class="fas fa-clock"></i>
                                            {{commentItem.CreatedAt | pretyDateUtc}}
                                        </span>
                                        <h3 class="timeline-header">
                                            <strong class="text-primary">
                                                {{listStaff.find((x)=> x.email === commentItem.CreatedBy) !== undefined ? listStaff.find((x) => x.email === commentItem.CreatedBy).fullName : ''}}
                                            </strong>
                                        </h3>
                                        <div class="timeline-body">
                                            <div>
                                                <div v-html="commentItem.MsgContent.replace(/\n/g, '<br>\n')" 
                                                     style="word-break: break-word;"
                                                     v-linkified></div>
                                            </div>
                                            <hr v-if="commentItem.mentionedList.length > 0" class="m-0 mt-2 mb-1"/>
                                            <div v-if="commentItem.mentionedList.length > 0">
                                                Mentioned: 
                                                <span :key="staff" v-for="staff in commentItem.mentionedList">
                                                    <strong>{{listStaff.find((x)=> x.email === staff) !== undefined ? listStaff.find((x) => x.email === staff).fullName : ''}}</strong>
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <!-- END timeline item -->
                            </div>
                            <div v-else v-loading="isLoading" class="d-flex flex-column flex-fill">
                                <div style="padding-right: 0px !important; background-color: white;"
                                     class="d-flex align-items-center justify-content-center flex-column pt-3 pb-3">
                                    <i class="el-icon-chat-line-square fa-6x" style="color: #e0e0e0;" />
                                    <div class="mt-2" style="color: #e0e0e0; font-size: 1rem">
                                        Write something..
                                    </div>
                                </div>
                            </div>
                        </div>
                    </el-tab-pane>
                    <el-tab-pane label="Response to customer" name="response">
                        <span slot="label"><i class="el-icon-user"></i> Conversation with user</span>
                        <div class="mb-2 d-flex" v-loading="loadingSubmit"
                             v-if="responseData.listData.length === 0">
                            <div class="image mr-2">
                                <img :src="`/img/avatars/${userInfo.userEmail}.jpg`"
                                     onerror="this.src='/img/avatar5.png'"
                                     style="width: 35px;"
                                     class="img-circle" />
                            </div>
                            <div class="mr-2 flex-fill">
                                <el-input type="textarea" placeholder="Enter content response to user..."
                                          v-model="responseModel.msgContent" />
                            </div>
                            <div>
                                <el-button type="primary" round size="small"
                                           :disabled="!responseModel.msgContent"
                                           @click="submitResponse">
                                    <span><i class="far fa-paper-plane"></i> Send</span>
                                </el-button>
                            </div>
                        </div>
                        <div class="mt-3">
                            <div class="timeline mb-1"
                                 v-if="responseData.listData.length > 0"
                                 style="margin-left: -1rem; margin-right: -1.5rem;">
                                <!-- timeline item -->
                                <div class="d-flex" :key="commentItem.id"
                                     v-for="commentItem in responseData.listData">
                                    <div class="image ml-3">
                                        <img :src="`/img/avatars/${commentItem.CreatedBy}.jpg`"
                                             v-if="commentItem.MsgDirection === 1"
                                             onerror="this.src='/img/avatar5.png'"
                                             style="width: 35px;"
                                             class="img-circle elevation-2" />
                                        <img :src="`/img/avatar5.png`"
                                             v-else
                                             style="width: 35px;"
                                             class="img-circle elevation-2" />
                                    </div>
                                    <div class="timeline-item ml-2 w-100"
                                         v-bind:class="{ 'customer-conv' : commentItem.MsgDirection === 2 }">
                                        <span class="time">
                                            <i class="fas fa-clock"></i>
                                            {{commentItem.CreatedAt | pretyDateUtc}}
                                        </span>
                                        <h3 class="timeline-header">
                                            <strong class="text-primary" v-if="commentItem.MsgDirection === 1">
                                                {{listStaff.find((x)=> x.email === commentItem.CreatedBy) !== undefined ? listStaff.find((x) => x.email === commentItem.CreatedBy).fullName : ''}}
                                            </strong>
                                            <span v-else>
                                                <strong class="text-primary">
                                                    {{commentItem.CreatedBy}}
                                                </strong>
                                                <span class="text-muted ml-1">- user</span>
                                            </span>
                                        </h3>
                                        <div class="timeline-body">
                                            <div>
                                                <!-- <div v-if="!element.isEditMode"> -->
                                                <!--<div v-html="element.noteContent.replace(/\n/g, '<br>\n')" v-linkified></div>-->
                                                <div v-html="commentItem.MsgContent.replace(/\n/g, '<br>\n')" 
                                                     style="word-break: break-word;"
                                                     v-linkified></div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <!-- END timeline item -->
                            </div>
                            <div v-else v-loading="isLoading" class="d-flex flex-column flex-fill">
                                <div style="padding-right: 0px !important; background-color: white;"
                                     class="d-flex align-items-center justify-content-center flex-column pt-3 pb-3">
                                    <i class="el-icon-chat-line-square fa-6x" style="color: #e0e0e0;" />
                                    <div class="mt-2" style="color: #e0e0e0; font-size: 1rem">
                                        Write something to the user
                                    </div>
                                </div>
                            </div>
                            <!--<div class="d-flex align-items-center justify-content-between"
                                 v-if="listData.length > 0"
                                 style="height: 3rem; background-color: white">
                                <div style="color: #9e9e9e; font-size: 0.7rem"
                                     class="d-flex flex-column ml-2">
                                    <div :style="`color: ${loadingStatus.color}`">
                                        {{ loadingStatus.text }}
                                    </div>
                                    <div>Last updated: {{ loadingStatus.date }}</div>
                                </div>
                                <el-pagination @current-change="handleCurrentChange"
                                               :current-page.sync="pagination.pageIndex"
                                               :page-size="pagination.pageSize"
                                               :total="pagination.outRowsNumber"
                                               :page-sizes="[20, 50, 100, 200, 500, 1000]"
                                               layout="total, prev, pager, next"></el-pagination>
                            </div>-->
                        </div>
                    </el-tab-pane>
                    
                </el-tabs>

            </div>
        </div>
    </div>
</template>

<style>
    .timeline-item.customer-conv {
        background: #ffe0c5 !important;
    }
</style>
<script>
    import { mapState } from "vuex";
    import base from '@/scripts/base';
    import api from '@/api/baseApi';
    const queryString = require('query-string');
    import EventBus from "@/event/EventBus";

    import commonAct from "@/commonActionHandle.js";
    // Constant
    import { API_CALL_STATUS } from "@/constant/config";
    import { SETTINGS_RATING_API } from "@/constant/apiSettings";
    
    export default {
        extends: base,
        data() {
            return {       
                tabCurrent: "internal",
                responseData: {
                    listData: [],         
                    pagination: {
                        querySearch: '',
                        orderBy: 'A.CreatedAt',
                        directionSort: 'DESC',
                        pageIndex: 1,
                        pageSize: 20,
                        outRowsNumber: 0,
                    },
                },
                internalResponseData: {
                    listData: [],         
                    pagination: {
                        querySearch: '',
                        orderBy: 'A.CreatedAt',
                        directionSort: 'DESC',
                        pageIndex: 1,
                        pageSize: 20,
                        outRowsNumber: 0,
                    },
                },
                loadingSubmit: false,
                loadingData: false,
                responseModel: {
                    msgContent: "",
                    ratingId: -1,
                    createdBy: ""
                },
                responseInternalModel: {
                    msgContent: "",
                    ratingId: -1,
                    createdBy: "",
                    notifyOnTelegram: false
                }
            };
        },
        props: {
            RatingDetailItem: {
                type: Object,
                default: null
            }
        },    
        watch: {
            RatingDetailItem(newVal) {
                this.responseModel.ratingId = newVal.id;
                this.responseModel.createdBy = this.userInfo.userEmail;
                this.responseInternalModel.ratingId = newVal.id;
                this.responseInternalModel.createdBy = this.userInfo.userEmail;
            }
        },
        computed: {
            ...mapState({
                userInfo: (state) => state.auth.user,
                listStaff: state => state.staffs,
                listServicePack: state => state.common.listServicePack,
                decentralization: state => state.auth.decentralization,
            }),
            newInternalResponseCount() {
                return this.internalResponseData.listData.filter(d => !JSON.parse(d.WasSeen).includes(this.$store.state.auth.user.userEmail)).length;
            }
        },
        created() {
            this.responseModel.ratingId = this.RatingDetailItem.id;
            this.responseModel.createdBy = this.userInfo.userEmail;
            this.responseInternalModel.ratingId = this.RatingDetailItem.id;
            this.responseInternalModel.createdBy = this.userInfo.userEmail;
            this.getData();
            this.getInternalResponseData();
        },
        methods: {

            // Pagination page change
            handleCurrentChange(element) {
                this.internalResponseData.pagination.pageIndex = element;
                this.getInternalResponseData();
            },
            // Get data
            getData() {
                this.isCheckAll = false;
                api.baseRequest(SETTINGS_RATING_API.RATING_RESPONSE, Object.assign(this.responseData.pagination, {
                    ratingId: this.RatingDetailItem.id
                }))
                    .then((res) => {
                        try {
                            let responseData = res.data;
                            let pagingItem = res.data.pagingItem;
                            this.responseData.listData = responseData.data;
                            this.responseData.listData.forEach(element => {

                                element.columnUser = this.mapColumnUser(element);
                            });
                            this.responseData.pagination.orderBy = pagingItem.orderBy;
                            this.responseData.pagination.directionSort = pagingItem.directionSort;
                            this.responseData.pagination.pageIndex = pagingItem.pageIndex;
                            this.responseData.pagination.pageSize = pagingItem.pageSize;
                            this.responseData.pagination.outRowsNumber = pagingItem.outRowsNumber;
                        } catch (error) {
                            console.log("getData -> error", error);
                        }
                    })
                    .catch(error => {
                        this.processCatchApiRequest(error, "Request failed ...", 5)
                    });
            },
            submitResponse() {
                commonAct.showConfirm(
                    `Are you sure response message to user ?`,
                    () => {

                        this.loadingSubmit = true;
                        api.baseRequest(SETTINGS_RATING_API.RATING_RESPONSE_SUBMIT, this.responseModel)
                            .then((res) => {
                                try {
                                    if (res.result === 0) {
                                        this.RatingDetailItem.ratingResponseCount++;
                                        this.responseModel.msgContent = "";
                                        this.getData();
                                        this.notificationSuccess("Success", "Perform action successed");
                                    }
                                } catch (error) {
                                    console.log("submitResponse -> error", error)
                                }
                                this.loadingSubmit = false;
                            })
                            .catch(error => {
                                console.log("submitResponse -> error", error)
                                this.loadingSubmit = false;
                            });
                    }
                );
            },
            getInternalResponseData() {
                this.isCheckAll = false;
                api.baseRequest(SETTINGS_RATING_API.RATING_INTERNAL_RESPONSE_VIEW, Object.assign(this.internalResponseData.pagination, {
                    ratingId: this.RatingDetailItem.id
                }))
                    .then((res) => {
                        try {
                            let responseData = res.data;
                            let pagingItem = res.data.pagingItem;
                            this.internalResponseData.listData = responseData.data;
                            this.internalResponseData.listData.forEach(element => {
                                if (element.Mentioned && element.Mentioned !== null) {
                                    try {
                                        element.mentionedList = JSON.parse(element.Mentioned);
                                    }
                                    catch (er) {
                                        console.error(er);
                                    }
                                }
                                else element.mentionedList = [];
                                element.columnUser = this.mapColumnUser(element);
                            });
                            this.internalResponseData.pagination.orderBy = pagingItem.orderBy;
                            this.internalResponseData.pagination.directionSort = pagingItem.directionSort;
                            this.internalResponseData.pagination.pageIndex = pagingItem.pageIndex;
                            this.internalResponseData.pagination.pageSize = pagingItem.pageSize;
                            this.internalResponseData.pagination.outRowsNumber = pagingItem.outRowsNumber;
                        } catch (error) {
                            console.error("getInternalResponseData -> error", error);
                        }
                    })
                    .catch(error => {
                        this.processCatchApiRequest(error, "Request failed ...", 5)
                    });
            },
            submitInternalResponse() {
                commonAct.showConfirm(
                    `Are you sure response message to user ?`,
                    () => {

                        this.loadingSubmit = true;
                        this.responseInternalModel.mentioned = JSON.stringify(this.responseInternalModel.mentionedList);
                        api.baseRequest(SETTINGS_RATING_API.RATING_INTERNAL_RESPONSE_SUBMIT, this.responseInternalModel)
                            .then((res) => {
                                try {
                                    if (res.result === 0) {
                                        this.RatingDetailItem.ratingResponseCount++;
                                        this.responseInternalModel.msgContent = "";
                                        this.responseInternalModel.mentioned = "";
                                        this.responseInternalModel.mentionedList = [];
                                        this.getInternalResponseData();
                                        this.notificationSuccess("Success", "Perform action successed");
                                    }
                                } catch (error) {
                                    console.log("submitResponse -> error", error)
                                }
                                this.loadingSubmit = false;
                            })
                            .catch(error => {
                                console.log("submitResponse -> error", error)
                                this.loadingSubmit = false;
                            });
                    }
                );
            },
            markRead() {
                commonAct.showConfirm(
                    `Mark read all comments ?`,
                    () => {

                        this.loadingData = true;
                        api.baseRequest(SETTINGS_RATING_API.RATING_INTERNAL_RESPONSE_MARKREAD, null, `/${this.RatingDetailItem.id}`)
                            .then((res) => {
                                try {                
                                    this.RatingDetailItem.ratingResponseUnreadCount = 0;
                                    this.getInternalResponseData();
                                    this.notificationSuccess("Success", "Perform action successed");
                                } catch (error) {
                                    console.log("submitResponse -> error", error)
                                }
                                this.loadingData = false;
                            })
                            .catch(error => {
                                console.log("submitResponse -> error", error)
                                this.loadingData = false;
                            });
                    }
                );
            },
            closeThread() {
                this.$prompt('Enter something to note for close this thread ', 'Close thread confirm ?', {
                    confirmButtonText: 'Yes, close this thread',
                    cancelButtonText: 'No, continue take care',
                    inputValidator: (value) => {
                        if (value === null || value === '') {
                            return 'Please input a note content..';
                        }
                        return true;
                    },
                }).then(({ value }) => {
                    this.notificationSuccess("Success", "Perform action successed");
                    let requestData = {
                        id: this.RatingDetailItem.id,
                        noteContent: value,
                    };
                    api.baseRequest(SETTINGS_RATING_API.RATING_CLOSE, requestData, null)
                        .then((res) => {
                            try {
                                let responseData = res.data;
                                this.$set(this.RatingDetailItem, "status", responseData.status);
                                this.notificationSuccess("Success", "Perform action successed");
                                EventBus.$emit('onThreadClosed', this.RatingDetailItem.id);
                            } catch (error) {
                                console.log("getData -> error", error);
                            }
                        })
                        .catch(error => {
                            this.processCatchApiRequest(error, "Request failed ...", 5);
                        });
                }).catch((error) => {
                    console.log(error);
                    this.$message({
                        type: 'info',
                        message: 'Close thread canceled'
                    });
                });
            }
        }
    }
</script>